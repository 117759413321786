import { ReactNode } from 'react'
import { CDN_URL } from 'services/config'

// const siteUrl = process.env.NEXT_PUBLIC_BASE_URL || ''

export interface PageDetailsInterface {
  meta: MetaInterface
  cssLinks?: string[]
  isNoFollow?: boolean
}

interface MetaInterface {
  title: string
  description: string
  type: string
  image: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IntrinsicAttributes = any

interface PagesDetailsInterface {
  [x: string]: IntrinsicAttributes &
    PageDetailsInterface & { children?: ReactNode }
  home: PageDetailsInterface
  contact: PageDetailsInterface
  services: PageDetailsInterface
  technology: PageDetailsInterface
  subtechnology: PageDetailsInterface
  webDevelopment: PageDetailsInterface
  teamExtension: PageDetailsInterface
  mobileDevelopment: PageDetailsInterface
  productEngineering: PageDetailsInterface
  qaServices: PageDetailsInterface
  work: PageDetailsInterface
  cloudandDevops: PageDetailsInterface
  uiuxDesign: PageDetailsInterface
  aboutUs: PageDetailsInterface
  blogListing: PageDetailsInterface
  careersListing: PageDetailsInterface
  ourTeam: PageDetailsInterface
  eventsGalleryPage: PageDetailsInterface
  getQuote: PageDetailsInterface
  'react-js-development-services': PageDetailsInterface
  'blockchain-development-services': PageDetailsInterface
  'ai-ml-development-services': PageDetailsInterface
  'chatbots-development': PageDetailsInterface
  'iot-app-development-company': PageDetailsInterface
  'data-analytics-services': PageDetailsInterface
  'magento-development-services': PageDetailsInterface
  'shopify-development-services': PageDetailsInterface
  'opencart-development-services': PageDetailsInterface
  'prestashop-development-services': PageDetailsInterface
  'woocommerce-development-services': PageDetailsInterface
  'drupal-development-services': PageDetailsInterface
  'nodejs-development-services': PageDetailsInterface
  'rust-development-services': PageDetailsInterface
  'python-development-company': PageDetailsInterface
  'java-development-services': PageDetailsInterface
  'php-development-services': PageDetailsInterface
  'goLang-development-services': PageDetailsInterface
  'angularjs-development-services': PageDetailsInterface
  'vuejs-development-company': PageDetailsInterface
  'nextjs-development-company': PageDetailsInterface
  'typescript-development-services': PageDetailsInterface
  'html5-development': PageDetailsInterface
  'react-native-app-development': PageDetailsInterface
  'flutter-app-development': PageDetailsInterface
  'swift-app-development': PageDetailsInterface
  'kotlin-app-development': PageDetailsInterface
  'ios-app-development': PageDetailsInterface
  'android-app-development-services': PageDetailsInterface
  'ionic-app-development': PageDetailsInterface
  'xamarin-development': PageDetailsInterface
  'hire-mern-stack-developers': PageDetailsInterface
  'hire-mean-stack-developers': PageDetailsInterface
  'django-development': PageDetailsInterface
  'flask-web-development': PageDetailsInterface
  'laravel-development-services': PageDetailsInterface
  'codeigniter-development-services': PageDetailsInterface
  'wordpress-development-services': PageDetailsInterface
  'spring-development': PageDetailsInterface
  'hibernate-development': PageDetailsInterface
  'aws-development': PageDetailsInterface
  'google-cloud-services': PageDetailsInterface
  'ibm-watson-developer': PageDetailsInterface
  'rasa-chatbot': PageDetailsInterface
  thankYou: PageDetailsInterface
  thankYou1: PageDetailsInterface
  AspDotnetMvc: PageDetailsInterface
  AzureDevelopment: PageDetailsInterface
  DotNetCoreDevelopment: PageDetailsInterface
  'dot-net-development-company': PageDetailsInterface
  eLearningLikeCoursera: PageDetailsInterface
  foodDeliveryLikeUberEats: PageDetailsInterface
  developAppLikeAirbnb: PageDetailsInterface
  costToMakeaVacationRentalApp: PageDetailsInterface
  createYourOwnElearningPlatform: PageDetailsInterface
  buildYourOwnElearningPlatform: PageDetailsInterface
  sportsBettingAppLikeDream: PageDetailsInterface
  sportsBettingAppBet: PageDetailsInterface
  elearningAppLikeUdemyLynda: PageDetailsInterface
  groceryDeliveryAppLikeInstacart: PageDetailsInterface
  socialNetworkingAppLikeInstagram: PageDetailsInterface
  ecommerceWebsiteLikeAmazonEbay: PageDetailsInterface
  taxiAppLikeUber: PageDetailsInterface
  foodDeliveryAppLikeZomato: PageDetailsInterface
  healthcareAppLikePracto: PageDetailsInterface
  healthcareAppLikeZocdoc: PageDetailsInterface
  costToCreateaHealthCareApp: PageDetailsInterface
  costToMakeaMedicalApp: PageDetailsInterface
  videoStreamingAppLikeNetflix: PageDetailsInterface
  musicVideoAppLikeTiktok: PageDetailsInterface
  jewelleryWebsiteAppDevelopment: PageDetailsInterface
  termsConditions: PageDetailsInterface
  privacyPolicyPage: PageDetailsInterface
  elearningAppsLikeByjus: PageDetailsInterface
  videoSharingAppsLikeYoutube: PageDetailsInterface
  appsLikeTinder: PageDetailsInterface
  developChatApps: PageDetailsInterface
  ecommerce: PageDetailsInterface
  azure: PageDetailsInterface
  'dotnet-core': PageDetailsInterface
  'dotnet-mvc': PageDetailsInterface
  ecommerceDevelopmentCompany: PageDetailsInterface
  penetrationTestingService: PageDetailsInterface
}

const PAGE_CONSTANT_TEXTS: PagesDetailsInterface = {
  home: {
    meta: {
      title:
        'Top Mobile App and Software Development Company - WebClues Infotech',
      description:
        'WebClues Infotech is a new-gen web & mobile app development company offering new possibilities & opportunities for businesses in every industry to grow with smart digital solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/home-page.jpg`,
    },
  },
  services: {
    meta: {
      title: 'Mobile App Development Services | Web Development Services',
      description:
        'Our web development services & mobile app development services leverage latest technologies to design & develop smart digital solutions that run seamlessly on all digital devices.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/services.jpg`,
    },
  },
  technology: {
    meta: {
      title: 'Web and Mobile App Development - Technology stack',
      description:
        'Build smart, interactive, and intuitive web and mobile app solutions using state-of-the-art technology stack. We use the latest Technology Stack For Android & iOS Mobile App Development.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/technology.jpg`,
    },
  },
  subtechnology: {
    meta: {
      title: 'Latest IT Tech Solution Development Company - WebClues Infotech',
      description:
        'WebClues Infotech believes to take advantage of new tech that is Internet of things dev, iBeacon App dev, AR Apps dev, Apple Watch App dev to create disruptive solutions.',
      type: 'website',
      image: ``,
    },
  },
  webDevelopment: {
    meta: {
      title:
        'Web Development Company | Web Development Services for Modern Businesses',
      description:
        'Choose a top-tier Web Development Company for expert web development services. Webclues delivers websites that are scalable and drive business growth.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/web-development.jpg`,
    },
  },
  teamExtension: {
    meta: {
      title: 'Team Extension Services | Hire Dedicated Developers',
      description:
        'Access expert talent with WebClues. Hire dedicated developers for custom solutions in web and development, ensuring quality, efficiency, and timely delivery.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/team-extension.jpg`,
    },
  },
  mobileDevelopment: {
    meta: {
      title:
        'Mobile App Development Services | Cross-platform Mobile App Solutions',
      description:
        'Discover Mobile App Development Services with innovation and expertise. WebClues, a leading mobile app development company, creates custom iOS & Android apps with robust functionality.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobility-solutions.jpg`,
    },
  },
  productEngineering: {
    meta: {
      title:
        'Software Product Engineering Services | Product Engineering Company',
      description:
        'WebClues can simplify the art of building complex apps with high-tech Product Engineering Services & Solutions. We build future-proof software products with innovative product engineering services.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/product-engineering.jpg`,
    },
  },
  qaServices: {
    meta: {
      title: 'Software Testing Services | Quality Assurance Services',
      description:
        'Ensure flawless performance with quality assurance services from WebClues. They include the best software testing solutions to deliver reliable and efficient software.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/quality-analyst.jpg`,
    },
  },
  contact: {
    meta: {
      title: 'Contact Us - WebClues Infotech',
      description:
        'Contact WebClues by filling out the form or meet us by visiting our offices in Ahmedabad, Rajkot, and Chennai. Find solutions to your problem and get help from our tech support team.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/contact-us.jpg`,
    },
  },
  work: {
    meta: {
      title: 'Portfolio | Web and Mobile App Development Company',
      description:
        'We deliver solutions that our clients love. Check out our portfolio section which is fueled by a passion for innovation and creation.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/portfolio.jpg`,
    },
  },
  cloudandDevops: {
    meta: {
      title: 'DevOps Consulting Services | DevOps Service Providers',
      description:
        'Accelerate development with DevOps Consulting Services from WebClues. Harness cloud-based solutions, automation, and agile workflows to boost efficiency & scalability.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/clouds-and-devops.jpg`,
    },
  },
  uiuxDesign: {
    meta: {
      title: 'UI/UX Development Company | Creative UI/UX Design Services',
      description:
        'WebClues is a UI/UX development company offering innovative UI/UX design services. Craft intuitive and visually compelling user interfaces to enhance user engagement.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ux-ui-design.jpg`,
    },
  },
  aboutUs: {
    meta: {
      title: 'About Us - Web and Mobile App Development',
      description:
        'Web and mobile app development company, we build websites, web apps, mobile apps, we experiment with new technologies (Blockchain, AI/ML, IoT)',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/about-us.jpg`,
    },
  },
  blogListing: {
    meta: {
      title: 'Blog - WebClues Infotech',
      description:
        'Featuring the latest and trending blogs related to web and mobile app development technology and services. We share insights and knowledge with everyone via our blog.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/blog.jpg`,
    },
  },
  careersListing: {
    meta: {
      title: 'Join Our Team | WebClues Infotech',
      description: 'Work with our creative and talented minds.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/current-opnings.jpg`,
    },
  },
  ourTeam: {
    meta: {
      title: 'Team - WebClues Infotech',
      description:
        'Meet our team who shares common goals, values, and are dedicatedly committed  towards their work',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/team.jpg`,
    },
  },
  eventsGalleryPage: {
    meta: {
      title: 'Events & Gallery - WebClues Infotech',
      description:
        'Take a glimpse at our celebrations, harmonious & nurturing work environment, and friendly culture.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/events.jpg`,
    },
  },
  getQuote: {
    meta: {
      title: 'Get a Quote - WebClues Infotech',
      description:
        'Let us know about your project and get a free quote for software development solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/get-a-quote.jpg`,
    },
  },
  'react-js-development-services': {
    meta: {
      title: 'Expert ReactJS Development Services | WebClues Infotech',
      description:
        'ReactJS development company delivering top-quality solutions with ReactJS development services. Hire dedicated ReactJS developers for responsive & dynamic web applications.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-react-js.jpg`,
    },
  },
  'blockchain-development-services': {
    meta: {
      title:
        'Blockchain Development Company | Custom Blockchain Consulting Services',
      description:
        'Choose a trusted Blockchain Development Company offering custom blockchain consulting services. Build secure, scalable solutions for smart contracts, dApps, and NFTs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-blockchain.jpg`,
    },
  },
  'ai-ml-development-services': {
    meta: {
      title:
        'AI/ML Development & Consulting Services Company | AI-Driven Business solutions',
      description:
        'Tap into tailored AI/ML Development Services from WebClues, an AI/ML development company delivering AI-driven solutions to automate workflows & enhance decision-making.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ai-ml-solutions-development.jpg`,
    },
  },
  'chatbots-development': {
    meta: {
      title: 'Chatbot App Development Company | Intelligent Chatbot Solutions ',
      description:
        'Partner with a leading Chatbot App Development Company offering advanced chatbot development services. Enhance customer engagement with intelligent chatbot solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-chatbot-app.jpg`,
    },
  },
  'iot-app-development-company': {
    meta: {
      title:
        'IoT App Development Company | IoT Solutions for Connected Experiences',
      description:
        'Choose a trusted IoT Development Company offering custom IoT app development services. Build smart, secure, and connected solutions to optimize your business operations.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-iot.jpg`,
    },
  },
  'data-analytics-services': {
    meta: {
      title: 'Data Analytics Company | Advanced Data Analytics Services',
      description:
        'Transform your business with a top Data Analytics Company. WebClues offers actionable insights through advanced data analytics services to help you make informed decisions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-data-analytics.jpg`,
    },
  },
  'magento-development-services': {
    meta: {
      title:
        'Magento Development Company | Bespoke Magento Web Development Services',
      description:
        'WebClues is a Magento Development Company offering modern eCommerce solutions. Our Magento web development services include creating scalable & feature-rich eCommerce sites. ',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/magento.jpg`,
    },
  },
  'shopify-development-services': {
    meta: {
      title:
        'Shopify Development Company | Expert Shopify Web Development Services',
      description:
        'Work with a leading Shopify Development Company for expert Shopify web development services. Build user-friendly eCommerce stores optimized for growth and engagement.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/shopify.jpg`,
    },
  },
  'opencart-development-services': {
    meta: {
      title:
        'OpenCart Development Company | OpenCart Web Design and Development',
      description:
        'Empower your business with a top OpenCart Development Company. WebClues offers OpenCart web design and development services, delivering effective & scalable online stores.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/opencart.jpg`,
    },
  },
  'prestashop-development-services': {
    meta: {
      title:
        'Prestashop Development Company | Prestashop Web Development Services',
      description:
        'Partner with a trusted Prestashop Development Company. Create an eCommerce store built for growth & reliability with advanced Prestashop web development services.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/prestashop.jpg`,
    },
  },
  'woocommerce-development-services': {
    meta: {
      title:
        'Woocommerce Development Company | Custom WooCommerce Development Services',
      description:
        'Revamp your eCommerce vision with a top-tier WooCommerce Development Company. Tap into WebClues’ WooCommerce development services to design flexible & potent online stores.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/woocommerce.jpg`,
    },
  },
  'drupal-development-services': {
    meta: {
      title:
        'Drupal Development Company | Expert Drupal Web Development Services',
      description:
        'WebClues is a trusted Drupal development company, delivering top-notch Drupal Development Services with a team of expert Drupal developers for dynamic web solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/drupal.jpg`,
    },
  },
  'nodejs-development-services': {
    meta: {
      title:
        'Node.JS Development Company | Scalable Node.JS Development Services',
      description:
        'Looking for expert Node.JS Development Services? WebClues, a leading Node.JS development company, lets you hire Node.JS developers to build efficient and robust solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-nodejs.jpg`,
    },
  },
  'rust-development-services': {
    meta: {
      title:
        'Rust Development Company | Secure & Scalable Rust Development Services',
      description:
        'Rust development services by WebClues focus on building secure & efficient software. As a Rust development company, it delivers reliable & scalable business solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-rust.jpg`,
    },
  },
  'python-development-company': {
    meta: {
      title: 'Python Development Company | Custom Python Development Services',
      description:
        'Python development services by WebClues empower businesses with custom solutions. As a Python development company, it delivers flexible & scalable software for modern needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-python.jpg`,
    },
  },
  'java-development-services': {
    meta: {
      title:
        'Java App Development Company | Hire Java Developers from WebClues',
      description:
        'WebClues offers Java development services tailored to enterprise needs. As a Java app development company, it delivers scalable solutions & provides skilled Java developers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-Java.jpg`,
    },
  },
  'php-development-services': {
    meta: {
      title: 'PHP Development Company | Custom PHP Development Services',
      description:
        'WebClues is a PHP development company that offers end-to-end PHP development services. Hire PHP Developers and get feature-rich PHP solutions in record time.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-php.jpg`,
    },
  },
  'goLang-development-services': {
    meta: {
      title:
        'GoLang Development Company | Scalable GoLang Development Services',
      description:
        'WebClues is a GoLang development company offering reliable GoLang development services. Build efficient applications designed to handle complex business operations.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-golang.jpg`,
    },
  },
  'angularjs-development-services': {
    meta: {
      title:
        'AngularJS Development Company | Hire Skilled AngularJS Developers',
      description:
        'WebClues is an AngularJS development company offering innovative AngularJS development services. Hire AngularJS developers to create robust, interactive web applications.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-angular-js.jpg`,
    },
  },
  'vuejs-development-company': {
    meta: {
      title: 'VueJS Development Company | Advanced VueJS Development Services',
      description:
        'Partner with a VueJS development company for cutting-edge VueJS development services. Build fast and flexible applications customized to unique business requirements.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-vue-js.jpg`,
    },
  },
  'nextjs-development-company': {
    meta: {
      title:
        'NextJS Development Company | Professional NextJS Development Services',
      description:
        'WebClues provides expert NextJS development services, specializing in server-side rendering. As a NextJS development company, it builds fast & efficient web solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-next-js.jpg`,
    },
  },
  'typescript-development-services': {
    meta: {
      title: 'TypeScript Development Company | Hire TypeScript Developer',
      description:
        'WebClues is a TypeScript development company providing top-tier TypeScript development services. Hire TypeScript developers to create scalable & maintainable applications.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-typescript.jpg`,
    },
  },
  'html5-development': {
    meta: {
      title:
        'HTML5 Web Development Company | Custom HTML5 Development Services',
      description:
        'Partner with a leading HTML5 development company for responsive web solutions. Hire skilled HTML5 developers to create dynamic websites customized to your needs',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-html5.jpg`,
    },
  },
  'react-native-app-development': {
    meta: {
      title:
        'React Native App Development Company | React Native Development Services',
      description:
        'WebClues provides advanced React Native Development Services and a skilled team to hire React Native developers for creating innovative, feature-rich mobile applications.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-react-native.jpg`,
    },
  },
  'flutter-app-development': {
    meta: {
      title: 'Flutter Development Company | Flutter App Development Services',
      description:
        'As a trusted Flutter development company, WebClues provides effective Flutter app development services. Build dynamic, cross-platform apps by hiring our expert developers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-flutter.jpg`,
    },
  },
  'swift-app-development': {
    meta: {
      title: 'Swift App Development Company | Swift Development Services',
      description:
        'Work with a top Swift app development company delivering premium Swift development services. Hire Swift developers to create feature-rich & efficient iOS applications.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-swift.jpg`,
    },
  },
  'kotlin-app-development': {
    meta: {
      title: 'Kotlin App Development Company | Kotlin App Development Services',
      description:
        'Choose a Koitlin app development company offering Kotlin app development services customized to your needs. Build faultless Android apps with expert Kotlin developers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-kotlin.jpg`,
    },
  },
  'ios-app-development': {
    meta: {
      title: 'iOS App Development Company | iPhone App Development Services',
      description:
        'Partner with a trusted iOS app development company delivering iPhone app development services. Hire skilled developers to create user-centric and secure iOS apps.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-ios.jpg`,
    },
  },
  'android-app-development-services': {
    meta: {
      title:
        'Android App Development Company | Android App Development Services',
      description:
        'As a leading Android app development company, WebClues offers custom Android app development services. Build high-performance apps with expert Android developers on hire.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-android.jpg`,
    },
  },
  'ionic-app-development': {
    meta: {
      title: 'Ionic App Development Company | Hire Ionic Developers',
      description:
        'WebClues is a top Ionic app development company providing efficient solutions. Hire expert Ionic developers to tap into cross-platform Ionic App Development Services.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-Ionic.jpg`,
    },
  },
  'xamarin-development': {
    meta: {
      title: 'Xamarin Development Company | Xamarin App Development Services',
      description:
        'Collaborate with a Xamarin App development company offering premium Xamarin development services. Hire Xamarin App developers to craft top-quality, cross-platform apps.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-xamarin.jpg`,
    },
  },
  'hire-mern-stack-developers': {
    meta: {
      title: 'MERN Stack Development Services | Hire MERN Stack Developers',
      description:
        'A trusted MERN Stack development company delivering innovative MERN Stack development services. Hire dedicated MERN stack developers to build high-performance web apps.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-mern.jpg`,
    },
  },
  'hire-mean-stack-developers': {
    meta: {
      title: 'MEAN Stack Development Company | MEAN Stack Development Services',
      description:
        'As a MEAN Stack development company, WebClues offers custom MEAN stack development services for scalable apps. Build efficient solutions with expert developers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-mean.jpg`,
    },
  },
  'django-development': {
    meta: {
      title: 'Django Development Company | Custom Django Development Services',
      description:
        'Choose a trusted Django development company for efficient web solutions. Hire Django developers to create feature-packed applications customized to your needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-django.jpg`,
    },
  },
  'flask-web-development': {
    meta: {
      title: 'Flask Web Development | Python based Flask Web Development',
      description:
        ' Opt for Python Flask web development services to build lightweight, high-performing applications. Partner with expert developers from WebClues for custom Flask solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-flask.jpg`,
    },
  },
  'laravel-development-services': {
    meta: {
      title: 'Laravel Development Company | Laravel Development Services',
      description:
        'Hire a reliable Laravel development company for top-notch Laravel development services. Build efficient web apps that meet your unique business requirements.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-laravel.jpg`,
    },
  },
  'codeigniter-development-services': {
    meta: {
      title:
        'CodeIgniter Development Company | CodeIgniter Development Services',
      description:
        'Work with a leading CodeIgniter development company offering custom CodeIgniter development services. Build high-performance web solutions with skilled developers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-codeIgniter.jpg`,
    },
  },
  'wordpress-development-services': {
    meta: {
      title:
        'WordPress Development Company | WordPress Website Development Services',
      description:
        'Partner with a top WordPress development company for end-to-end WordPress website development services. Design SEO-friendly websites that elevate your online presence.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-wordpress.jpg`,
    },
  },
  'spring-development': {
    meta: {
      title:
        'Spring Web Application Development Company | Spring Framework Development Services',
      description:
        'Choose a Spring web application development company for efficient Spring framework development services. Build innovative enterprise applications with expert developers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-spring.jpg`,
    },
  },
  'hibernate-development': {
    meta: {
      title: 'Hibernate Development Company | Hibernate Development Services',
      description:
        'Harness the expertise of a Hibernate development company for tailored Hibernate development services. Create optimized ORM solutions for faultless data integration.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-hibernate.jpg`,
    },
  },
  'aws-development': {
    meta: {
      title: 'AWS Development & Consulting Services | Scalable Cloud Solutions',
      description:
        'Explore AWS Development & Consulting Services for scalable cloud solutions. Partner with WebClues & utilize AWS cloud computing to enhance efficiency.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-aws.jpg`,
    },
  },
  'google-cloud-services': {
    meta: {
      title: 'Google Cloud Service Providers | Google Cloud Computing Services',
      description:
        'As leading Google Cloud Service Providers, we offer expert Google Cloud Computing Services. Hire certified Google Cloud developers for innovative cloud solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-google-cloud.jpg`,
    },
  },
  'ibm-watson-developer': {
    meta: {
      title: 'IBM Watson Services | IBM Watson Consulting Service',
      description:
        'Tap into IBM Watson Services for AI-driven solutions. Hire IBM Watson developers or opt for IBM Watson Consulting Services to build data-driven apps with WebClues.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-ibm-watson.jpg`,
    },
  },
  'rasa-chatbot': {
    meta: {
      title: 'Hibernate Development Company | Hibernate Development Services',
      description:
        'Hire Hibernate Developer from us. We provide bespoke Hibernate Development Services. Our hibernate framework developers build high-performance solutions for every business.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-chatbot-app.jpg`,
    },
  },
  thankYou: {
    meta: {
      title: 'Thank You - WebClues Infotech',
      description:
        'Thank you for reaching out to us for your web and mobile app development needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/thank-you.jpg`,
    },
  },
  thankYou1: {
    meta: {
      title: 'Thank You Contact | WebClues Infotech',
      description:
        'Thank you for reaching out to us for your web and mobile app development needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/thank-you.jpg`,
    },
  },
  AspDotnetMvc: {
    meta: {
      title:
        'Microsoft ASP.NET MVC Development Services | Custom MVC Solutions',
      description:
        'WebClues’ Microsoft ASP.NET MVC development services provide robust solutions for enterprise needs. Hire ASP.NET MVC developers to craft custom applications.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-asp-dot-net.jpg`,
    },
    cssLinks: ['/assets/css/dotnet_mvc_blog_page.css'],
  },
  AzureDevelopment: {
    meta: {
      title: 'Microsoft Azure Development Services | Hire Azure Developers',
      description:
        'WebClues offers Microsoft Azure development services customized to your needs. Hire Azure developers to build effective cloud applications that drive business success.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-azure.jpg`,
    },
    cssLinks: ['/assets/css/microsoft_azure_blog_page_v3.css'],
  },
  DotNetCoreDevelopment: {
    meta: {
      title: '.Net Core Development company | .NET Core Development Services',
      description:
        'Partner with a .NET Core development company offering premium ASP.Net Core development services. Hire .NET Core developers to build feature-rich enterprise solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-dotnet-core.jpg`,
    },
    cssLinks: ['/assets/css/dotnet_core_blog_page_v2.css'],
  },
  'dot-net-development-company': {
    meta: {
      title: '.NET Development Company | ASP.NET Development Services',
      description:
        'As a leading .NET development company, WebClues specializes in secure & scalable solutions. It offers .NET development services that meet unique business challenges.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-dotnet.jpg`,
    },
  },
  eLearningLikeCoursera: {
    meta: {
      title: 'How to build an Elearning app or website like Coursera',
      description:
        'WebClues provides a detailed guide on how to build an app/website like Coursera, How much it cost & what are essential features required. Get consultation!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/app-like-coursera.jpg`,
    },
    cssLinks: ['/assets/css/coursera_page.css'],
  },
  buildYourOwnElearningPlatform: {
    meta: {
      title: 'How to build an Elearning app or website like Coursera',
      description:
        'WebClues provides a detailed guide on how to build an app/website like Coursera, How much it cost & what are essential features required. Get consultation!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/e-learning-app-like-coursera.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/coursera_page.css'],
  },
  foodDeliveryLikeUberEats: {
    meta: {
      title: 'Cost to create a food delivery application similar to UberEats',
      description:
        'How to build food delivery app like UberEats; consult experts at WebClues. Get complete insight on how to build & how much it cost to build app like UberEats.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/food-delivery-app-like-uber-eats.jpg`,
    },
    cssLinks: ['/assets/css/uber_eats_page.css'],
  },
  developAppLikeAirbnb: {
    meta: {
      title: 'How Much Does it Cost to Develop an App like Airbnb?',
      description:
        'Looking to build an app like Airbnb for Android & iOS platform? Get insights into how to make an app like Airbnb & how much it will cost with WebClues.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/how-much-does-It-cost-to-make-an-app-like-airbnb.jpg`,
    },
    cssLinks: ['/assets/css/arbnn_blog_page.css'],
  },
  costToMakeaVacationRentalApp: {
    meta: {
      title: 'How Much Does it Cost to Develop an App like Airbnb?',
      description:
        'Looking to build an app like Airbnb for Android & iOS platform? Get insights into how to make an app like Airbnb & how much it will cost with WebClues.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/how-much-does-It-cost-to-make-an-app-like-airbnb.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/arbnn_blog_page.css'],
  },
  sportsBettingAppLikeDream: {
    meta: {
      title: 'How much does it cost to build fantasy sports app like Dream 11',
      description:
        'Want to know the cost to develop fantasy sports betting app like Dream11? Get complete guide on how to build fantasy sport app like Dream11 from WebClues.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/sports-betting-app-like-dream11.jpg`,
    },
    cssLinks: ['/assets/css/applikedream.css'],
  },
  sportsBettingAppBet: {
    meta: {
      title: 'How Much It Cost to Develop Sports Betting App Like Bet365?',
      description:
        'How much does it cost to develop sports betting app like Bet365? Contact us to know the complete details related to the development of apps like Bet365.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/sports-betting-app-like-bet365.jpg`,
    },
    cssLinks: ['/assets/css/bet365_page.css'],
  },
  elearningAppLikeUdemyLynda: {
    meta: {
      title: 'Cost to build an app like Udemy | eLearning App Udemy & Lynda',
      description:
        'Develop eLearning mobile apps like Udemy/Lynda with WebClues. Also, find out how much it costs to build an app like Udemy/Lynda by talking to our experts.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/app-like-udemy-lynda.jpg`,
    },
    cssLinks: ['/assets/css/udemy_blog_page.css'],
  },
  createYourOwnElearningPlatform: {
    meta: {
      title: 'Cost to build an app like Udemy | eLearning App Udemy & Lynda',
      description:
        'Develop eLearning mobile apps like Udemy/Lynda with WebClues. Also, find out how much it costs to build an app like Udemy/Lynda by talking to our experts.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/e-learning-website-and-app-like-udemy.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/udemy_blog_page.css'],
  },
  groceryDeliveryAppLikeInstacart: {
    meta: {
      title: 'How much it cost to build a grocery delivery app like Instacart?',
      description:
        'A step by step guide on building an app like Instacart. Get the estimation on the cost of developing grocery app like Instacart for both Android & iOS.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/grocery-delivery-app-like-instacart.jpg`,
    },
    cssLinks: ['/assets/css/instacart_page.css'],
  },
  socialNetworkingAppLikeInstagram: {
    meta: {
      title: 'How to create an app like Instagram and know how much it cost?',
      description:
        'Want to build an app like Instagram? Checkout its development cost, architecture plan, timeline, & tech-stack. Get detailed information.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/photo-sharing-app-like-instagram.jpg`,
    },
    cssLinks: ['/assets/css/instagram_blog_page.css'],
  },
  ecommerceWebsiteLikeAmazonEbay: {
    meta: {
      title: 'How to Make a Website Like Amazon: Tech-Stack, Cost, Feature',
      description:
        'Looking for Amazon clone script or eCommerce website development company? Hire WebClues & get best mobile app developers. Book a FREE consultation!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ecommerce-app-like-amazon.jpg`,
    },
    cssLinks: ['/assets/css/amazon_blog_page.css'],
  },
  foodDeliveryAppLikeZomato: {
    meta: {
      title: 'Cost to develop a food delivery app like Zomato?',
      description:
        'Develop a food ordering app like Zomato. Get a 360-degree overview of what, why, and how to create a food delivery app like Zomato.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/food-delivery-app-like-zomato.jpg`,
    },
    cssLinks: ['/assets/css/zomato_blog_page.css'],
  },
  taxiAppLikeUber: {
    meta: {
      title: 'How to build an app like Uber - Tech & Feature Considerations',
      description:
        "Build an app like Uber with WebClues. We are taxi booking app development company specializing in creating custom clone apps. We create taxi booking apps tailored to your business needs, matching Uber's aesthetics and features.",
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/taxi-app-like-uber.jpg`,
    },
    cssLinks: ['/assets/css/uber_blog_page.css'],
  },
  healthcareAppLikePracto: {
    meta: {
      title: 'How to make a Healthcare app|  Cost to develop app like Practo',
      description:
        'Build on-demand medical app like Practo with WebClues. Now, booking appointments, health checkups, ordering medicines online is easy. Consult today!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/healthcare-app-like-practo.jpg`,
    },
    cssLinks: ['/assets/css/practo_blog_page.css'],
  },
  costToCreateaHealthCareApp: {
    meta: {
      title: 'How to make a Healthcare app|  Cost to develop app like Practo',
      description:
        'Build on-demand medical app like Practo with WebClues. Now, booking appointments, health checkups, ordering medicines online is easy. Consult today!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/healthcare-app-like-practo.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/practo_blog_page.css'],
  },
  healthcareAppLikeZocdoc: {
    meta: {
      title: 'Build an app/website like Zocdoc | Features, Cost, Timeline',
      description:
        'WebClues employs unparalleled approaches in building doctor appointment booking app like ZocDoc. Get right consultation on costing, timeline, & features',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/healthcare-app-like-zocdoc.jpg`,
    },
    cssLinks: ['/assets/css/app-like-zocdoc.css'],
  },
  costToMakeaMedicalApp: {
    meta: {
      title: 'Build an app/website like Zocdoc | Features, Cost, Timeline ',
      description:
        'WebClues employs unparalleled approaches in building doctor appointment booking app like ZocDoc. Get right consultation on costing, timeline, & features',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/healthcare-app-like-zocdoc.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/app-like-zocdoc.css'],
  },
  videoStreamingAppLikeNetflix: {
    meta: {
      title: 'How to create an app like Netflix and how much does it cost?',
      description:
        'Choose the best app development company & get a complete guide on how to build an app like Netflix. Get latest video stream app features & know about cost of development.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/video-streaming-app-like-netflix.jpg`,
    },
    cssLinks: ['/assets/css/netflix_blog_page.css'],
  },
  musicVideoAppLikeTiktok: {
    meta: {
      title:
        'Complete guide on how to create an app like TikTok - WebClues Infotech',
      description:
        'Build a video-streaming app like TikTok both for Android & iOS. Know how much does it cost to build an app like TikTok. Get detailed information on building app like TikTok.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/music-app-like-tiktok.jpg`,
    },
    cssLinks: ['/assets/css/tiktok_blog_page.css'],
  },
  jewelleryWebsiteAppDevelopment: {
    meta: {
      title: 'Best Jewelry App or Website Development Company',
      description:
        'Get a custom jewelry mobile app/website for easy jewelry shopping. Hire jewelry app developers & take your jewelry business to next level. Get a free quote!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/jewellery-website-app-development.jpg`,
    },
    cssLinks: ['/assets/css/jewellery_blog_page.css'],
  },
  termsConditions: {
    meta: {
      title: 'Terms and Conditions - WebClues Infotech',
      description:
        'Our Terms and Conditions serve many useful legal purposes. It is the legal backbone of the relationship between our services and our clients.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/terms-and-conditions.jpg`,
    },
  },
  privacyPolicyPage: {
    meta: {
      title: 'Privacy Policy WebClues Infotech',
      description:
        'We at WebClues Infotech are committed to protecting the privacy of our users. We only collect the important information that we actually need to provide services to you.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/privacy-policy.jpg`,
    },
  },
  elearningAppsLikeByjus: {
    meta: {
      title: "Build eLearning app like BYJU's | Cost to build app like BYJU's",
      description:
        "WebClues can develop eLearning app like BYJU's with custom functionalities. Cost of development $8000 - $35000. Turn your idea into a profitable investment.",
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/e-learning-app-like-byjus.jpg`,
    },
    cssLinks: ['/assets/css/byjus_blog_page.css'],
  },
  videoSharingAppsLikeYoutube: {
    meta: {
      title: 'How Much Does it Cost to Build Video Sharing App like Youtube',
      description:
        'Know How Much it Cost to Build Video Sharing App like YouTube. Consult the expertise of WebClues & get all the insights on how to build an app like YouTube.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/video-sharing-app-like-youtube.jpg`,
    },
    cssLinks: ['/assets/css/youtube_blog_page.css'],
  },
  appsLikeTinder: {
    meta: {
      title: 'How much does it cost to build a dating app like Tinder?',
      description:
        'Ready to build app like Tinder! WebClues team has the right skill of developing dating apps based on an innovative feature. Get your Tinder app today!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/dating-app-like-tinder.jpg`,
    },
    cssLinks: ['/assets/css/tinder_blog_page.css'],
  },
  developChatApps: {
    meta: {
      title: 'How Much Does It Cost To Develop A Chat App',
      description:
        'WebClues - Chat App Development Company helps you build custom chat app. Know the features, technology stack & how much it costs to develop chat app.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/cost-to-develop-a-chat-app.jpg`,
    },
    cssLinks: ['/assets/css/chat_blog_page.css'],
  },
  ecommerce: {
    meta: {
      title: '',
      description: '',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ecommerce-app-like-amazon.jpg`,
    },
  },
  azure: {
    meta: {
      title: '',
      description: '',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-azure.jpg`,
    },
  },
  'dotnet-core': {
    meta: {
      title: '',
      description: '',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-dotnet-core.jpg`,
    },
  },
  'dotnet-mvc': {
    meta: {
      title: '',
      description: '',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-dotnet.jpg`,
    },
  },
  ecommerceDevelopmentCompany: {
    meta: {
      title: 'eCommerce Development Company | Hire eCommerce Developers',
      description:
        'Trust our CMMI Level 5 certified eCommerce development company with 8+ yrs of exp & a team of 160+ experts to elevate your online business. Experience excellence in eCommerce solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ecommerce-development-company.jpg`,
    },
  },
  generativeAIPage: {
    meta: {
      title: 'Generative AI Solutions for Your Business | WebClues Infotech',
      description:
        'At WebClues, a generative AI development company, we help businesses harness the transformative power of generative AI. Contact us today to learn more',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-ai.jpg`,
    },
  },
  penetrationTestingService: {
    meta: {
      title: 'Penetration Testing Services | WebClues Infotech',
      description:
        'Strengthen cybersecurity with WebClues Penetration Testing Services. Identify vulnerabilities through in-depth assessments to ensure protection against threats.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/penetration-testing-service.jpg`,
    },
  },
}

export default PAGE_CONSTANT_TEXTS
